import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    instance: null,
  },
  getters: {
    instance (state) { return state.instance },
  },
  mutations: {
    setInstance (state, payload) { state.instance = payload },
  },
  actions: {
    setInstance ({ commit }, payload) { commit('setInstance', payload) },
  },
})
