<template>
  <v-app>
    <template v-if="$route.path==='/login'">
      <router-view></router-view>
    </template>
    <template v-else>
      <v-app-bar app color="primary" dark flat>
        <div @click="$router.push('/')" class="d-flex align-center" style="cursor: pointer">
          <span class="headline">BizBrain Messaging</span>
        </div>
        <v-spacer></v-spacer>
        <div class="mt-1 text-uppercase" v-if="status.instanceSession">
          {{ status.instanceSession.instance_status }}
        </div>
        <v-btn outlined depressed class="mt-1 mx-2 success" v-if="isInstanceUp===true">RUNNING</v-btn>
        <v-btn @click="stopInstance" outlined depressed class="mt-1 mx-2 warning" v-if="isInstanceUp===true">STOP
        </v-btn>
        <v-btn outlined depressed class="mt-1 mx-2 error" v-else-if="isInstanceUp===false" @click="startInstance">START
          INSTANCE
        </v-btn>
        <v-btn class="mt-1 mx-2" outlined text depressed v-else>STATUS</v-btn>
        <v-select @change="instanceUpdate" label="Select Instance" outlined style="max-width: 180px" dense
                  item-text="instance_name" v-model="currentInstance" item-value="instance_id" hide-details flat filled
                  :items="instances" return-object class="mt-1 mr-2"></v-select>
        <v-tooltip nudge-bottom="50" nudge-right="150">
          <template v-slot:activator="{ on }">
            <v-btn @click.prevent="logout" class="error--text" icon v-on="on">
              <v-icon>mdi-power</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
        <router-view :instance="currentInstance" :url="currentInstanceURL"></router-view>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { io } from 'socket.io-client'
import Constants from '@/Constants/Constants'
import NetworkCommunicator from '@/plugins/NetworkResourceHandler'
import axios from 'axios'
import { EventBus } from '@/plugins/EventBus'

let socket

export default {
  name: 'App',
  data () {return { instances: [], currentInstance: null, currentInstanceURL: null, isInstanceUp: null, status: {} }},
  mounted () {
    this.instances = localStorage.instances ? JSON.parse(localStorage.instances) : []
  },
  methods: {
    async logout () {
      localStorage.clear()
      this.$router.push('/login').catch(() => {})
    },
    instanceUpdate () {
      this.currentInstanceURL = `${Constants.api_base_url}:${this.currentInstance.instance_data.port}/`
      axios.post(`${Constants.api_url}connectivityStatus`, { instanceId: this.currentInstance.instance_id }).
          then(res => {
            this.isInstanceUp = res.data.flag
            // this.initializeSocket()
          }).catch(() => {this.isInstanceUp = false}).finally(() => {
        this.currentInstance['isInstanceUp'] = this.isInstanceUp
        this.$store.dispatch('setInstance', this.currentInstance)
        EventBus.$emit('instanceUpdate', this.currentInstance)
        this.$forceUpdate()
      })
      this.getInstanceStatus()
      setInterval(() => {if (this.currentInstance && this.currentInstance.isInstanceUp) this.getInstanceStatus() },
          10000)
    },
    initializeSocket () {
      if (this.isInstanceUp) {
        socket = io(this.currentInstanceURL)
        socket.on('connect', function (a) { console.log('connected ', a) })
        socket.on('disconnect', function (a) { console.log('disconnected', a) })
      }
    },
    startInstance () {
      NetworkCommunicator('POST', `${Constants.server_url}startInstance`, { instance: this.currentInstance }).
          then(res => {
            console.log(res)
            setTimeout(() => {
              this.instanceUpdate()
              setTimeout(() => {
                NetworkCommunicator('POST', `${this.currentInstanceURL}setup/restoreSession`,
                    { instance: this.currentInstance }).
                    then(res2 => {console.log(res2)}).catch(err2 => {console.log(err2)})
              }, 2500)
            }, 2500)
          })
    },
    getInstanceStatus () {
      axios.post(`${Constants.api_url}getInstanceStatus`, { instanceId: this.currentInstance.instance_id }).
          then(res => {
            this.status = res.data.status
            EventBus.$emit('instanceStatusUpdate', this.status)
          })
    },
    stopInstance () {
      NetworkCommunicator('POST', `${Constants.server_url}stopInstance`, { instance: this.currentInstance }).
          then(res => {
            console.log(res)
            setTimeout(() => {this.instanceUpdate()}, 2500)
            // socket.close()
          })
    },
  },
}
</script>
