import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login', name: 'Login', meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login'),
  },
  {
    path: '/', name: 'Home', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home'),
  }, {
    path: '/setup', name: 'Setup', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Setup" */ '../views/Setup'),
  },
  {
    path: '/history', name: 'History', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "History" */ '../views/History'),
  },
]

const router = new VueRouter({
  mode: 'history', base: process.env.BASE_URL, routes,
})

export default router

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!localStorage.getItem('token')) {
      router.replace({ path: '/login' }).catch(() => {})
    } else { next() }
  } else {
    if (localStorage.getItem('token')) {
      router.replace({ path: '/' }).catch(() => {})
    } else { next() }
  }
})
