const Constants = {
  base_url: process.env.BASE_URL,
  api_url: process.env.VUE_APP_API_URL,
  server_url: process.env.VUE_APP_SERVER_URL,
  api_base_url: process.env.VUE_APP_API_BASE_URL,
  menuItems: [
    { name: 'Dashboard', to: '/dashboard', icon: 'mdi-view-dashboard' },
    { name: 'Customers', to: '/customers', icon: 'mdi-account-multiple' },
    { name: 'Users', to: '/users', icon: 'mdi-account-group' },
  ],
  successToast: {
    theme: 'primary', position: 'top-right',
    duration: 3000, className: 'success-toast',
  },
  errorToast: {
    theme: 'primary', position: 'top-right',
    duration: 3000, className: 'error-toast',
  },
}
export default Constants
