import axios from 'axios'

export function setHeader () {
  axios.defaults.headers.common['token'] = localStorage.getItem('token')
}

setHeader()

function NetworkCommunicator (method, url, postData) {
  if (method === 'GET') {
    return axios.get(url).then(response => {
      return response.data
    }).catch(function (error) {
      console.log(error)
    })
  } else if (method === 'DELETE') {
    return axios.delete(url).then(response => {
      return response.data
    }).catch(function (error) {
      console.log(error)
    })
  } else if (method === 'POST') {
    return axios.post(url, postData).then(response => {
      return response.data
    }).catch(function (error) {
      console.log(error)
    })
  } else if (method === 'PUT') {
    return axios.put(url, postData).then(response => {
      return response.data
    }).catch(function (error) {
      console.log(error)
    })
  }
}

export default NetworkCommunicator
